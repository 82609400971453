<template>
    <div>
        <v-chip
        :class="[`ma-2 ${colorTextMap}--text`]"
        :color="colorMap"
        small
        >
            <span class="subtitle-2">{{ manufacturerName }}</span>
        </v-chip>
    </div>
</template>

<script>
export default {
    props: {
        manufacturerId: {
            type: Number,
            default: 0,
            required: true
        },
        manufacturerName: {
            type: String,
            default: '',
            required: true
        },
    }, 
   computed: {
       colorMap() {
           if(this.manufacturerName.toLowerCase().includes('astrazeneca')) {
                return 'astrazenecaChip';
            }
            else if(this.manufacturerName.toLowerCase().includes('moderna')) {
                return 'modernaChip';
            } 
            else if(this.manufacturerName.toLowerCase().includes('janssen')) {
                return'johnsonChip';  
            }
            else if(this.manufacturerName.toLowerCase().includes('novavax')) {
                return'novavaxChip';
            }
            else if(this.manufacturerName.toLowerCase().includes('pfizer')) {
                return'pfizerChip'; 
            }
            else if(this.manufacturerName.toLowerCase().includes('glaxo')) {
                return 'glaxosmithklineChip'; 
            }
       },
       colorTextMap() {
           if(this.manufacturerName.toLowerCase().includes('astrazeneca')) {
                return 'astrazenecaChipText';
            }
            else if(this.manufacturerName.toLowerCase().includes('moderna')) {
                return 'modernaChipText'; 
            } 
            else if(this.manufacturerName.toLowerCase().includes('janssen')) {
                return 'johnsonChipText'; 
            }
            else if(this.manufacturerName.toLowerCase().includes('novavax')) {
                return 'novavaxChipText'; 
            }
            else if(this.manufacturerName.toLowerCase().includes('pfizer')) {
                return 'pfizerChipText'; 
            }
            else if(this.manufacturerName.toLowerCase().includes('glaxo')) {
                return 'glaxosmithklineChipText'; 
            }
       }
   },
}
</script>

<style>

</style>